<template>
  <div>
    <!-- Terminate Services Modal -->
    <cancel-service v-if="showCancelPanel"
      :visible.sync="showCancelPanel"
      :service-uid="cancelUid" />

    <!-- Main Service Wrapper -->
    <div :class="(port.lagPrimary || port.lagPortCount) ? 'port-line lag-group' : ''">
      <!-- LAG Header -->
      <div v-if="port.lagPrimary || port.lagPortCount"
        class="flex-row-centered lag-box flex-wrap p-0">
        <!-- LAG Header: Title -->
        <span class="lag-header">{{ $t('productNames.lagLong') }}:</span>
        <!-- LAG Header: Status Info Popover + Icon -->
        <lazy-popover width="fit-content"
          placement="top"
          :open-delay="500"
          trigger="hover">
          <!-- LAG Header Status Popover: Content -->
          <div class="text-align-left fs-1-4rem p-1">
            <!-- LAG Header Status Popover: LAG Status -->
            <p class="text-align-center my-0">
              <strong>{{ statusInfo.lagMessage }}</strong>
            </p>
            <!-- LAG Header Status Popover: Sub-LAGs Statuses -->
            <p><strong>{{ $t('ports.statuses') }}</strong></p>
            <div v-for="(status, index) in statusInfo.statuses"
              :key="index"
              class="flex-row-centered position-relative">
              <!-- Sub-LAG: Status Icon -->
              <div class="service-status-icon"
                :class="status.status" />
              <!-- Sub-LAG: Name -->
              <p class="ml-1 my-0-5">
                {{ status.portName }}
              </p>
            </div>
          </div>
          <!-- LAG Header Status Popover: Reference (Icon) -->
          <template #reference>
            <!-- Status Icon -->
            <div class="service-status-icon mx-0-5"
              :class="statusInfo.lagStatus" />
          </template>
        </lazy-popover>
        <!-- LAG Header: Speed + Capacity -->
        <span>
          {{ port._speed }}
          <template v-if="port._capacity && port._capacity.percent >= 100">
            <strong>({{ $tc('ports.percent-allocated', port._capacity.percent, { percent: port._capacity.percent }) }})</strong>
          </template>
          <template v-else>
            ({{ $tc('ports.percent-allocated', port._capacity ? port._capacity.percent : 0, { percent: port._capacity ? port._capacity.percent : 0 }) }})
          </template>
        </span>

        <!-- LAG Header: Diversity Zone -->
        <div v-if="showDesignDiversityZone || deployedDiversityZone"
          role="presentation"
          class="ml-1">
          <!-- LAG in design (not yet ordered) -->
          <span v-if="showDesignDiversityZone"
            :title="$t(`services.${designDiversityZone}-zone`)">
            {{ $t('services.diversity-zone') }}
            <i class="far fa-dot-circle"
              :class="`diversity-color-${designDiversityZone}`"
              aria-hidden="true" />
            <!--
              Diverse from only exists for LAGs not yet ordered as once
              they are there is no way of linking those services together
            -->
            <template v-if="isPort && port.config.diverseFrom">
              {{ $t('ports.diverse-from', {port: getProductNameFromPortUid(port.config.diverseFrom)}) }}
            </template>
          </span>
          <!-- Deployed LAG -->
          <span v-else-if="deployedDiversityZone"
            :title="$t(`services.${deployedDiversityZone}-zone`)">
            {{ $t('services.diversity-zone') }}
            <i class="far fa-dot-circle"
              :class="`diversity-color-${deployedDiversityZone}`"
              aria-hidden="true" />
          </span>
        </div>

        <!-- LAG Header: Right-End Buttons -->
        <div class="lag-buttons flex-row-centered ml-auto">
          <!-- Add (+) Connection: Tooltip + Button -->
          <el-tooltip placement="top"
            :content="determineAddConnectionTooltipContent(true)"
            :open-delay="500">
            <div>
              <el-button :disabled="!canConnect"
                name="addConnection"
                class="add-connection action-button"
                :data-serviceid="port.productUid"
                :data-name="`${simplifiedServiceType}AddConnection`"
                :data-testid="`addConnection-${port._subUid}`"
                @click="$router.push(`/create-connection/${port.productUid}`, () => {})">
                <div class="flex-row-centered flex-justify-center">
                  <i class="fal fa-plus-circle action-icon"
                    aria-hidden="true" />
                  <span class="connection-text">
                    {{ $t('connections.connection') }}
                  </span>
                </div>
              </el-button>
            </div>
          </el-tooltip>
          <!-- LAG: Tooltip + Button -->
          <el-tooltip v-if="addedLagPort"
            placement="top"
            :content="$t('ports.configure-details')"
            :open-delay="500">
            <div>
              <el-button name="showDetails"
                class="action-button"
                :data-testid="`showDetails-${port._subUid}`"
                @click="showDetails(addedLagPort)">
                <div class="flex-row-centered flex-justify-center">
                  <i class="fal fa-cog action-icon"
                    aria-hidden="true" />
                  <span class="connection-text">
                    {{ $t('productNames.lag') }}
                  </span>
                </div>
              </el-button>
            </div>
          </el-tooltip>
          <!-- Add (+) Port: Tooltip + Button -->
          <el-tooltip v-else-if="!disabledFeatures.addPortToLag"
            placement="top"
            :content="$t(canAddPort ? 'ports.add-ports-to-lag' : 'ports.lag-full')"
            :open-delay="500">
            <div>
              <el-button :disabled="!canAddPort"
                class="action-button"
                data-name="addPort"
                :data-serviceid="port.productUid"
                :data-testid="`addPort-${port._subUid}`"
                @click="$router.push(`/add-port-to-lag/${port.aggregationId}/${port.lagId}`, () => {})">
                <div class="flex-row-centered flex-justify-center">
                  <i class="fal fa-plus-circle action-icon"
                    aria-hidden="true" />
                  <span class="connection-text">
                    {{ $t('productNames.ports') }}
                  </span>
                </div>
              </el-button>
            </div>
          </el-tooltip>
          <!-- Show/Hide Associated Connections (^): Tooltip + Button -->
          <el-tooltip v-if="hasAssociatedConnections"
            placement="top"
            :content="(isCollapsed) ? $t('connections.show-connections') : $t('connections.hide-connections')"
            :open-delay="500">
            <el-button class="collapse-button action-button"
              @click="handleExpandCollapse">
              <i class="fal fa-chevron-up action-icon expand-arrow"
                :class="isCollapsed ? 'active' : ''"
                aria-hidden="true" />
            </el-button>
          </el-tooltip>
        </div>
      </div>

      <!-- Main Service Card -->
      <div id="intro-step-5"
        class="port-line service-box"
        :class="{filterMatch: matchingServices.includes(port.productUid)}"
        :data-service-name="port.productName"
        :data-service-type="port.productType"
        :data-provisioning-status="port.provisioningStatus">
        <!-- Visual Connecting Lines between primary LAG Port and First Port in LAG -->
        <div v-if="subLagCount || hasAssociatedConnections"
          class="connection-arrow--port-bottom"
          :class="subLagCount ? 'lag-arrow' : ((port.lagPrimary || port.lagPortCount) ? 'last-lag' : '')" />

        <!-- Main Service Status Info: Popover + Icon -->
        <lazy-popover width="fit-content"
          placement="top"
          :open-delay="500"
          trigger="hover">
          <!-- Main Service Status Info Popover: Content -->
          <div class="text-align-left fs-1-4rem p-1">
            <!-- Main Service Status Info Popover: Main Service Status -->
            <p class="text-align-center my-0">
              <strong>{{ getStatusInfo(port).message }}</strong>
            </p>
          </div>

          <template #reference>
            <mu-mega-icon :icon="getServiceIcon(port)"
              class="service-icon service-status-indicator"
              :class="[getStatusInfo(port).status, getWhiteLabelClass(port)]"
              :data-service-name="port.productName"
              :data-status="port.provisioningStatus"
              @click="showDetails(port)" />
          </template>
        </lazy-popover>

        <!-- Main Service Card: Details + Spinner -->
        <div v-loading="isLoading"
          class="service-info-text cursor-pointer"
          @click="showDetails(port)">
          <!-- Main Service Header -->
          <div class="service-header"
            :data-service-name="port.productName"
            :data-service-type="port.productType"
            :data-provisioning-status="port.provisioningStatus">
            <!-- Main Service Header: Service Name -->
            {{ port.productName }}
            <!-- Main Service Header: Service Sub-UID -->
            <el-tooltip v-if="!serviceIsInDesign && port._subUid"
              placement="right"
              :content="$t('services.identifier-copy')"
              :open-delay="500">
              <span class="service-identifier"
                :data-testid="`serviceIdentifier-${port._subUid}`"
                @click.stop="copyToClipboard(port._subUid)">
                #{{ port._subUid }}
              </span>
            </el-tooltip>
            <!-- Main Service Header: Service Provisioning Status -->
            <span v-if="!serviceIsLive"
              class="ml-0-5">
              ({{ snakeToCapitalizedWords(port.provisioningStatus) }})
            </span>
            <!-- Main Service Header: Download LOA -->
            <span v-if="port.provisioningStatus === G_PROVISIONING_CONFIGURED && !isMve && !isMcr && !disabledFeatures.downloadLoa">
              <el-tooltip :content="$t('ports.waiting')"
                placement="top"
                :open-delay="500">
                <el-button type="text"
                  class="ml-1"
                  data-name="download-loa"
                  :data-testid="`downloadLoa-${port._subUid}`"
                  @click.stop="downloadLoa(port.productUid)">
                  <i class="fas fa-file-download"
                    aria-hidden="true" /> {{ $t('ports.download-loa') }}
                </el-button>
              </el-tooltip>
            </span>
          </div>
          <!-- Main Service Details -->
          <!-- Main Service Details: First Line -->
          <div class="service-details"
            :data-speed="calculatePortSpeedText(port)">
            <!-- MVE Details First Line: Vendor + Size -->
            <template v-if="isMve">
              <span>{{ $t('ports.vendor') }}: {{ mveVendor }}, {{ $t('general.size') }}: {{ mveSizeText }}</span>
            </template>
            <!-- Port/MCR Details First Line -->
            <template v-else>
              <!-- Port/MCR Details First Line: Service Type + Speed -->
              {{ $t(isMcr ? 'productNames.mcr' : 'productNames.port') }} {{ calculatePortSpeedText(port) }}
              <!-- In-DESIGN LAG Ports Details First Line: LAG Port Count -->
              <template v-if="port.lagPortCount">
                {{ $tc('ports.count-port-lag', port.lagPortCount, {count: port.lagPortCount}) }}
              </template>
              <!-- Port/MCR Details First Line: Capacity -->
              <template v-if="!port.lagPrimary && !port.lagPortCount">
                <template v-if="port._capacity && port._capacity.percent >= 100">
                  <strong>({{ $tc('ports.percent-allocated', port._capacity.percent, { percent: port._capacity.percent }) }})</strong>
                </template>
                <template v-else>
                  ({{ $tc('ports.percent-allocated', port._capacity ? port._capacity.percent : 0, { percent: port._capacity ? port._capacity.percent : 0 }) }})
                </template>
              </template>
            </template>
          </div>
          <!-- Main Service Details: Second Line -->
          <div class="service-details">
            <!-- Main Service Details Second Line: Location -->
            <template v-if="port._location && port._location.formatted">
              {{ port._location.formatted.long }}
            </template>
            <!-- Main Service Details Second Line: Unknown Location -->
            <template v-else>
              {{ $t('general.unknown-location') }}
            </template>
            <!-- Main Service Details Second Line: Diversity Zone -->
            <!-- Service in design (not yet ordered) -->
            <span v-if="showDesignDiversityZone"
              :title="$t(`services.${designDiversityZone}-zone`)">
              -
              {{ $t('services.diversity-zone') }}
              <i class="far fa-dot-circle"
                :class="`diversity-color-${designDiversityZone}`"
                aria-hidden="true" />
              <!--
                Diverse from only exists for ports not yet ordered as once
                they are there is no way of linking those services together
              -->
              <template v-if="isPort && port.config.diverseFrom">
                {{ $t('ports.diverse-from', {port: getProductNameFromPortUid(port.config.diverseFrom)}) }}
              </template>
            </span>
            <!-- Deployed services -->
            <span v-else-if="deployedDiversityZone"
              :title="$t(`services.${deployedDiversityZone}-zone`)">
              -
              {{ $t('services.diversity-zone') }}
              <i class="far fa-dot-circle"
                :class="`diversity-color-${deployedDiversityZone}`"
                aria-hidden="true" />
            </span>
          </div>
          <conflict :service="port" />
        </div>

        <!-- Main Service Card: Action Buttons -->
        <div class="action-block flex-column">
          <!-- Main Service Card Action Buttons: First Row -->
          <div class="tool-buttons"
            role="menubar">
            <!-- MCR Looking Glass: Tooltip + Button -->
            <el-tooltip v-if="isMcr && !serviceIsInDesign"
              placement="top"
              :content="$t('menu.mcr-looking-glass')"
              :open-delay="500">
              <router-link :to="`/tools/mcr-looking-glass/${port.productUid}`">
                <el-button name="showMcrLookingGlass"
                  class="action-button"
                  :data-testid="`showMcrLookingGlass-${port._subUid}`"
                  @click="trackButtonClick('looking-glass')">
                  <i class="fal fa-binoculars action-icon"
                    aria-hidden="true" />
                </el-button>
              </router-link>
            </el-tooltip>
            <!-- Move Connections: Tooltip + Button (Adjust logic according to requirements) -->
            <el-tooltip placement="top"
              :content="moveConnectionsTooltip"
              :open-delay="500"
              popper-class="move-connection-tooltip">
              <!-- Don't use router-link here otherwise we can't properly disable navigation -->
              <el-button name="moveConnections"
                class="action-button"
                data-testid="move-connections"
                :disabled="!canMoveConnections"
                @click="moveConnections">
                <i class="fal fa-exchange action-icon"
                  aria-hidden="true" />
              </el-button>
            </el-tooltip>
            <!-- Service Details: Tooltip + Button -->
            <el-tooltip placement="top"
              :content="determineDetailsTooltipContent(port)"
              :open-delay="500">
              <el-button name="showDetails"
                class="action-button"
                :data-testid="`showDetails-${port._subUid}`"
                @click="showDetails(port)">
                <i class="fal fa-cog action-icon"
                  aria-hidden="true" />
              </el-button>
            </el-tooltip>
            <!-- View in Map: Tooltip + Button -->
            <el-tooltip v-if="!disabledFeatures.dashboard"
              placement="top"
              :content="$t('map.view-in-map')"
              :open-delay="500">
              <div>
                <el-button name="show-on-map"
                  class="action-button"
                  label="map"
                  @click="showOnMap(port, true)">
                  <i class="fal fa-location-dot action-icon" />
                </el-button>
              </div>
            </el-tooltip>

            <!-- Service Keys: Tooltip + Button -->
            <el-tooltip v-if="canCreateServiceKeys"
              placement="top"
              :content="$t('ports.service-keys')"
              :open-delay="500">
              <el-button data-name="serviceKeys"
                class="action-button"
                :data-testid="`editServiceKeys-${port._subUid}`"
                @click="showServiceKeyModal = true">
                <i class="fal fa-key action-icon"
                  aria-hidden="true" />
              </el-button>
            </el-tooltip>

            <!-- Delete Service: Tooltip + Button -->
            <el-tooltip placement="top"
              :content="getDeleteButtonData(port).message"
              :open-delay="500">
              <div>
                <el-button :disabled="!getDeleteButtonData(port).canDelete"
                  class="action-button"
                  data-name="deleteService"
                  :data-last-sublag="isPortLastLag"
                  :data-testid="`deleteService-${port._subUid}`"
                  @click="toggleCancelService(port)">
                  <i class="fal fa-trash-alt action-icon"
                    aria-hidden="true" />
                </el-button>
              </div>
            </el-tooltip>
            <!-- Lock Service: Tooltip + Button -->
            <el-tooltip v-show="!port.locked && !port.adminLocked && showLock"
              placement="top"
              :content="determineLockTooltipContent(port)"
              :open-delay="500">
              <div>
                <el-button v-loading="checkIfLoading(port)"
                  :disabled="!isAdmin || !serviceIsLive"
                  name="lock"
                  class="action-button"
                  :data-testid="`lockService-${port._subUid}`"
                  @click="lockPort(port)">
                  <i class="fal fa-unlock-alt action-icon"
                    aria-hidden="true" />
                </el-button>
              </div>
            </el-tooltip>
            <!-- Unlock Service: Tooltip + Button -->
            <el-tooltip v-show="port.locked || port.adminLocked"
              placement="top"
              :content="determineLockTooltipContent(port)"
              :open-delay="500">
              <div>
                <el-button v-loading="checkIfLoading(port)"
                  :disabled="!isAdmin || !serviceIsLive || port.adminLocked"
                  name="unlock"
                  class="action-button"
                  :data-testid="`unlockService-${port._subUid}`"
                  @click="unlockPort(port)">
                  <i class="fal fa-lock action-icon"
                    :class="{adminLocked:port.adminLocked}"
                    aria-hidden="true" />
                </el-button>
              </div>
            </el-tooltip>
          </div>

          <!-- Main Service Card Action Buttons: Second Row -->
          <div v-if="!port.lagPrimary && !port.lagPortCount"
            class="tool-buttons"
            role="menubar">
            <!-- Add (+) Connection: Tooltip + Button -->
            <div class="add-connection-holder">
              <el-tooltip placement="left"
                :content="determineAddConnectionTooltipContent()"
                :open-delay="500">
                <div>
                  <!-- Don't use router-link here otherwise we can't properly disable navigation -->
                  <el-button :disabled="!canConnect"
                    name="addConnection"
                    class="add-connection action-button"
                    :data-serviceid="port.productUid"
                    :data-name="`${simplifiedServiceType}AddConnection`"
                    :data-testid="`addConnection-${port._subUid}`"
                    @click="addConnection()">
                    <div class="flex-row-centered flex-justify-center">
                      <i class="fal fa-plus-circle action-icon"
                        aria-hidden="true" />
                      <span class="connection-text">
                        {{ $t('connections.connection') }}
                      </span>
                    </div>
                  </el-button>
                </div>
              </el-tooltip>
            </div>
            <!-- Show/Hide Associated Connections (^): Tooltip + Button -->
            <el-tooltip v-if="!port.lagPrimary && !port.lagPortCount && hasAssociatedConnections"
              placement="top"
              :content="(isCollapsed) ? $t('connections.show-connections') : $t('connections.hide-connections')"
              :open-delay="500">
              <el-button class="action-button"
                :data-testid="`toggleConnectionCollapse-${port._subUid}`"
                @click="handleExpandCollapse">
                <i class="fal fa-chevron-up action-icon expand-arrow"
                  :class="isCollapsed ? 'active' : ''"
                  aria-hidden="true" />
              </el-button>
            </el-tooltip>
          </div>
        </div>
      </div>

      <!-- Sub-LAGs -->
      <template v-if="port._subLags">
        <div v-for="lag in port._subLags"
          :key="lag.productUid"
          class="port-line service-box"
          :class="{filterMatch: matchingServices.includes(lag.productUid)}"
          :data-service-name="lag.productName"
          :data-service-type="lag.productType"
          :data-provisioning-status="lag.provisioningStatus"
          :data-testid="`lag-${lag._subUid}`">
          <div class="connection-arrow--lag-top" />
          <div v-if="!isLastLag(lag) || hasAssociatedConnections"
            class="connection-arrow--port-bottom"
            :class="isLastLag(lag) ? 'last-lag' : 'lag-arrow'" />

          <lazy-popover width="fit-content"
            placement="top"
            :open-delay="500"
            trigger="hover">
            <div class="text-align-left fs-1-4rem p-1">
              <p class="text-align-center my-0">
                <strong>{{ getStatusInfo(lag).message }}</strong>
              </p>
            </div>

            <template #reference>
              <mu-mega-icon icon="PORT"
                class="service-icon service-status-indicator"
                :class="[getStatusInfo(lag).status, getWhiteLabelClass(lag)]"
                :data-status="lag.provisioningStatus"
                :data-testid="`lag-icon-showDetails-${lag._subUid}`"
                @click="showDetails(lag)" />
            </template>
          </lazy-popover>
          <div v-loading="isLoading"
            class="cursor-pointer min-width-300px ml-5"
            :data-testid="`lag-showDetails-${lag._subUid}`"
            @click="showDetails(lag)">
            <div class="service-header">
              {{ lag.productName }}
              <el-tooltip v-if="lag.provisioningStatus !== G_PROVISIONING_DESIGN && lag._subUid"
                placement="right"
                :content="$t('services.identifier-copy')"
                :open-delay="500">
                <span class="service-identifier"
                  :data-testid="`lag-serviceIdentifier-${lag._subUid}`"
                  @click.stop="copyToClipboard(lag._subUid)">#{{ lag._subUid }}</span>
              </el-tooltip>
              <span v-if="lag.provisioningStatus !== G_PROVISIONING_LIVE"
                class="ml-0-5">({{ snakeToCapitalizedWords(lag.provisioningStatus) }})</span>
              <span v-if="lag.provisioningStatus === G_PROVISIONING_CONFIGURED && !disabledFeatures.downloadLoa">
                <el-tooltip :content="$t('ports.waiting')"
                  placement="top"
                  :open-delay="500">
                  <el-button type="text"
                    class="ml-1"
                    data-name="download-loa"
                    :data-testid="`lag-downloadLoa-${lag._subUid}`"
                    @click.stop="downloadLoa(lag.productUid)">
                    <i class="fas fa-file-download"
                      aria-hidden="true" /> {{ $t('ports.download-loa') }}
                  </el-button>
                </el-tooltip>
              </span>
            </div>
            <div class="service-details"
              :data-speed="calculatePortSpeedText(lag)">
              {{ $t('productNames.port') }} {{ calculatePortSpeedText(lag) }}
              <template v-if="lag.lagPortCount">
                {{ $tc('ports.count-port-lag', lag.lagPortCount, { count:lag.lagPortCount }) }}
              </template>
              <template v-else>
                - {{ getFormattedLagLocation(lag) }}
              </template>
              ({{ $t('ports.link-aggregation', { portName:port.productName }) }})
            </div>
            <conflict :service="lag" />
          </div>

          <div class="action-block tool-buttons">
            <el-tooltip placement="top"
              :content="$t('general.type-details', { type: $t('productNames.port') })"
              :open-delay="500">
              <el-button name="showDetails"
                class="action-button"
                data-name="showLagDetails"
                :data-testid="`lag-showDetails-${lag._subUid}`"
                @click="showDetails(lag)">
                <i class="fal fa-cog action-icon"
                  aria-hidden="true" />
              </el-button>
            </el-tooltip>

            <el-tooltip v-if="!disabledFeatures.dashboard"
              placement="top"
              :content="$t('map.view-in-map')"
              :open-delay="500">
              <div>
                <el-button name="show-on-map"
                  label="map"
                  @click="showOnMap(lag, true)">
                  <i class="fal fa-location-dot action" />
                </el-button>
              </div>
            </el-tooltip>

            <el-tooltip placement="top"
              :content="getDeleteButtonData(lag).message"
              :open-delay="500">
              <div>
                <el-button :disabled="!getDeleteButtonData(lag).canDelete"
                  class="action-button"
                  data-name="deleteService"
                  :data-last-sublag="isLastLag(lag)"
                  :data-testid="`lag-deleteService-${lag._subUid}`"
                  @click="toggleCancelService(lag)">
                  <i class="fal fa-trash-alt action-icon"
                    aria-hidden="true" />
                </el-button>
              </div>
            </el-tooltip>

            <el-tooltip v-show="!lag.locked && !lag.adminLocked && showLock"
              placement="top"
              :content="determineLockTooltipContent(lag)"
              :open-delay="500">
              <div>
                <el-button v-loading="checkIfLoading(lag)"
                  :disabled="!isAdmin || lag.provisioningStatus !== G_PROVISIONING_LIVE"
                  name="lock"
                  class="action-button"
                  :data-testid="`lag-lock-${lag._subUid}`"
                  @click="lockPort(lag)">
                  <i class="fal fa-unlock-alt action-icon"
                    aria-hidden="true" />
                </el-button>
              </div>
            </el-tooltip>
            <el-tooltip v-show="lag.locked || lag.adminLocked"
              placement="top"
              :content="determineLockTooltipContent(lag)"
              :open-delay="500">
              <div>
                <el-button v-loading="checkIfLoading(lag)"
                  :disabled="!isAdmin || lag.provisioningStatus !== G_PROVISIONING_LIVE || lag.adminLocked"
                  name="unlock"
                  class="action-button"
                  :data-testid="`lag-unlock-${lag._subUid}`"
                  @click="unlockPort(lag)">
                  <i class="fal fa-lock action-icon"
                    :class="{adminLocked:lag.adminLocked}"
                    aria-hidden="true" />
                </el-button>
              </div>
            </el-tooltip>
          </div>
        </div>
      </template>
    </div>

    <!-- Main Service Sub-Content -->
    <div class="port-sub-content">
      <!-- Associated Connections (VXCs/IXs) Collapsible -->
      <template v-if="hasAssociatedConnections">
        <el-collapse-transition>
          <div v-show="!isCollapsed">
            <vxc-line v-for="vxc in port.associatedVxcs"
              :key="vxc.productUid"
              :vxc="vxc"
              :port="port"
              @showOnMap="showOnMap" />
            <ix-line v-for="ix in port.associatedIxs"
              :key="ix.productUid"
              :ix="ix"
              :port="port"
              @showOnMap="showOnMap" />
          </div>
        </el-collapse-transition>

        <div v-show="isCollapsed"
          class="configured-extras services-collapsed text-align-center cursor-pointer p-0-5"
          @click="isCollapsed = false">
          <el-button size="mini"
            :data-testid="`expandConnectionPanel-${port._subUid}`">
            <i class="fal fa-chevron-double-down"
              aria-hidden="true" />
            {{ $tc('ports.services-attached-count', associatedConnectionsCount, {count: associatedConnectionsCount}) }}
            <i class="fal fa-chevron-double-down"
              aria-hidden="true" />
          </el-button>
        </div>
      </template>

      <!-- No Associated Connections Wrapper -->
      <template v-else>
        <!-- Quick Connect Panel -->
        <div v-if="canConnect && !disabledFeatures.quickConnect"
          class="configured-extras">
          <quick-connect :port="port" />
        </div>
        <div v-else
          class="height-1rem" />
      </template>
    </div>

    <service-keys :visible.sync="showServiceKeyModal"
      :service="port"
      :service-type="simplifiedServiceType" />
  </div>
</template>

<script>
// External tools
import { mapState, mapGetters, mapActions, mapMutations } from 'vuex'
import sdk from '@megaport/api-sdk'
// Internal tools
import captureSentryError from '@/utils/CaptureSentryError.js'
import {
  copyToClipboard,
  convertSpeed,
  capitalizeFirstOnly,
  snakeToCapitalizedWords,
  formatMveVendorName,
} from '@/helpers.js'
import { connectionNoMoveReason, portlikeNoMoveReason } from '@/utils/moveConnections'
import { downloadFile } from '@/utils/downloadFile'
import { captureEvent, productTypeToEvent } from '@/utils/analyticUtils'
// Components
import VXCComponent from '@/components/services/Vxc.vue'
import IXComponent from '@/components/services/Ix.vue'
import ConflictComponent from '@/components/services/Conflict.vue'
import QuickConnectComponent from '@/components/QuickConnect.vue'
import CancelServiceComponent from '@/components/cancel/Cancel.vue'
import LazyPopoverComponent from '@/components/ui-components/LazyPopover.vue'
import ServiceKeysComponent from '@/components/services/ServiceKeys.vue'

export default {
  name: 'PortsLags',

  components: {
    'vxc-line': VXCComponent,
    'ix-line': IXComponent,
    conflict: ConflictComponent,
    'quick-connect': QuickConnectComponent,
    'cancel-service': CancelServiceComponent,
    'lazy-popover': LazyPopoverComponent,
    'service-keys': ServiceKeysComponent,
  },

  inject: ['disabledFeatures'],

  props: {
    port: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      isCollapsed: false,
      loadingUid: null,
      showServiceKeyModal: false,
      showCancelPanel: false,
      cancelUid: null,
      isLoading: false,
    }
  },

  computed: {
    ...mapState('Services', ['services']),
    ...mapGetters('Auth', ['hasAuth', 'isManagedAccount', 'isPartnerAccount', 'hasFeatureFlag']),
    ...mapGetters('Services', ['doesPortHaveUntaggedServices', 'myPorts', 'showLock']),
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('ServiceFilters', ['matchingServices']),

    showDesignDiversityZone() {
      return this.serviceIsInDesign && this.designDiversityZone
    },
    designDiversityZone() {
      return this.port.config?.diversityZone
    },
    deployedDiversityZone() {
      return this.port.diversityZone
    },
    /**
     *  Status info will be as follows:
     *
     * If LAG
     * lagStatus - the status class for the overall LAG
     * lagMessage - whether the LAG is up or down (up if any port is up)
     *
     * statuses (statuses for each port in the LAG, or just one status for a standalone
     *           port as per below format. This needs to be ordered for LAG display)
     *
     * status {
     *   productUid
     *   portName
     *   status: status class for the port
     *   message: heading for the port - up or down
     * }
     */
    statusInfo() {
      // Initialize our basic information
      const statusInfo = {
        lagStatus: '', // Status for overall LAG
        lagMessage: '', // Message for overall LAG
        statuses: [], // Array of statuses for each port in the LAG (or just the port if not part of a LAG)
      }

      const allPorts = [this.port].concat(this.port._subLags || [])

      for (const currentPort of allPorts) {
        const currentPortStatusInfo = {
          productUid: currentPort.productUid,
          portName: currentPort.productName,
          status: '',
          message: '',
        }

        if (currentPort.provisioningStatus !== this.G_PROVISIONING_LIVE) {
          currentPortStatusInfo.message = `${this.$t('services.provisioning-status')}: ${currentPort.provisioningStatus}`
        } else if (typeof currentPort.up !== 'boolean') {
          currentPortStatusInfo.status = 'partial-success'
          currentPortStatusInfo.message = this.$t('services.unknown-status')
        } else {
          currentPortStatusInfo.status = currentPort.up ? 'all-go' : 'all-fail'
          currentPortStatusInfo.message = currentPort.up ? this.$t('services.up') : this.$t('services.down')
        }

        statusInfo.statuses.push(currentPortStatusInfo)
      }

      // Check whether we have all the LAG statuses filled in
      // to determine the status of the primary port in the LAG
      if (this.port.lagPrimary) {
        let filledStatuses = 0
        let operational = false

        for (const currentStatusInfo of statusInfo.statuses) {
          if (currentStatusInfo.status.length) {
            filledStatuses++

            if (currentStatusInfo.status === 'all-go') {
              operational = true
            }
          }
        }

        // If we have all the LAG statuses filled in,
        // set the port's status info based on the port's operational status
        if (filledStatuses === statusInfo.statuses.length) {
          statusInfo.lagStatus = operational ? 'all-go' : 'all-fail'
          statusInfo.lagMessage = operational ? this.$t('ports.lag-up') : this.$t('ports.lag-down')
        }
      }

      return statusInfo
    },
    canConnect() {
      const portIsNewOrInDesignDeploy = [this.G_PROVISIONING_DESIGN_DEPLOY, this.G_PROVISIONING_NEW].includes(this.port.provisioningStatus)
      return (portIsNewOrInDesignDeploy || !this.port._location) ? false : !this.doesPortHaveUntaggedServices(this.port)
    },
    canAddPort() {
      // If we have already added a LAG port with potentially multiple ports, then we don't want
      // the add button displayed
      if (!this.port.lagPrimary || !this.port._subLags) {
        return false
      }
      for (const lag of this.port._subLags) {
        if (lag.lagPortCount) {
          return false
        }
      }
      return this.subLagCount < 7
    },
    canCreateServiceKeys() {
      const constraints = !this.serviceIsInDesign
        && this.port.provisioningStatus !== this.G_PROVISIONING_DESIGN_DEPLOY
        && this.canConnect
        && this.canPlaceOrder

      // Allow creation of service keys for ports, MCRs and MVEs if FF is enabled
      if (this.hasFeatureFlag('mve_mcr_service_keys')) {
        return (this.isMve || this.isMcr || this.isPort) && constraints
      }

      // Disable creation of service keys for MCRs and MVEs otherwise
      return !this.isMcr && !this.isMve && constraints
    },
    canPlaceOrder() {
      return this.hasAuth('place_order')
    },
    isAdmin() {
      return this.hasAuth('company_admin')
    },
    addedLagPort() {
      if (this.port.lagPortCount) {
        return this.port
      }
      if (this.port._subLags) {
        for (const lag of this.port._subLags) {
          if (lag.lagPortCount) {
            return lag
          }
        }
      }
      return null
    },
    isPortLastLag() {
      if (!this.port.lagPrimary) {
        return false
      }
      if (this.subLagCount === 0) {
        return true
      }
      if (this.port.lagPortCount) {
        return true
      }
      return false
    },
    mveSizeText() {
      const size = this.serviceIsInDesign ? this.port.vendorConfig?.mveLabel || capitalizeFirstOnly(this.port.vendorConfig?.productSize) : this.port.mveLabel
      return size
    },
    mveVendor() {
      return this.port.vendor ? formatMveVendorName(this.port.vendor) : this.port.vendorConfig?._vendor
    },
    isMve() {
      return this.port.productType === this.G_PRODUCT_TYPE_MVE
    },
    isPort() {
      return this.port.productType === this.G_PRODUCT_TYPE_MEGAPORT
    },
    isMcr() {
      return this.port.productType === this.G_PRODUCT_TYPE_MCR2
    },
    serviceIsInDesign() {
      return this.port.provisioningStatus === this.G_PROVISIONING_DESIGN
    },
    serviceIsLive() {
      return this.port.provisioningStatus === this.G_PROVISIONING_LIVE
    },
    simplifiedServiceType() {
      let simplifiedServiceType = null

      switch (this.port.productType) {
        case this.G_PRODUCT_TYPE_MEGAPORT:
          simplifiedServiceType = this.$t('productNames.port')
          break
        case this.G_PRODUCT_TYPE_MCR2:
          simplifiedServiceType = this.$t('productNames.mcr')
          break
        case this.G_PRODUCT_TYPE_MVE:
          simplifiedServiceType = this.$t('productNames.mve')
          break
      }

      if (this.port.lagPrimary || this.port.lagPortCount) {
        simplifiedServiceType = this.$t('productNames.lag')
      }

      return simplifiedServiceType
    },
    associatedVxcsCount() {
      return this.port.associatedVxcs.length
    },
    associatedIxsCount() {
      return this.port.associatedIxs.length
    },
    associatedConnectionsCount() {
      return this.associatedVxcsCount + this.associatedIxsCount
    },
    hasAssociatedConnections() {
      return this.associatedConnectionsCount > 0
    },
    portNoMoveReason() {
      return portlikeNoMoveReason(this.port)
    },
    canMoveConnections() {
      if (this.portNoMoveReason) return false
      return [...this.port.associatedVxcs, ...this.port.associatedIxs]
        .some(connection => connectionNoMoveReason(connection) == null)
    },
    moveConnectionsTooltip() {
      if (!this.hasAuth('modify_service')) return this.$t('services.move-permission-denied')

      let productTypeKey
      switch (this.port.productType) {
        case this.G_PRODUCT_TYPE_MCR2:
          productTypeKey = 'productNames.mcr'
          break
        case this.G_PRODUCT_TYPE_MVE:
          productTypeKey = 'productNames.mve'
          break
        default:
          productTypeKey = this.port.lagPrimary || this.port.lagPortCount ? 'productNames.lag' : 'productNames.port'
      }

      if (this.serviceIsInDesign) return this.$t('services.port-move-notlive', { type: this.$t(productTypeKey) })
      if (this.portNoMoveReason) return this.$t(this.portNoMoveReason)
      if (!this.canMoveConnections) return this.$t('services.no-movable-type', { type: this.$t(productTypeKey) })
      return this.$t('ports.move-connections')
    },
    subLagCount() {
      if (!this.port._subLags) return null

      return this.port._subLags.length
    },
  },

  watch: {
    isCollapsed(newValue) {
      const collapsedStatesString = localStorage.getItem('portCollapsedStates')
      const collapsedStates = collapsedStatesString ? JSON.parse(collapsedStatesString) : {}
      collapsedStates[this.port.productUid] = newValue
      localStorage.setItem('portCollapsedStates', JSON.stringify(collapsedStates))
    },
  },

  created() {
    const collapsedStatesString = localStorage.getItem('portCollapsedStates')
    const collapsedStates = collapsedStatesString ? JSON.parse(collapsedStatesString) : {}
    if (this.disabledFeatures.expandedServices) {
      this.isCollapsed = true
    } else if (collapsedStates[this.port.productUid]) {
      this.isCollapsed = collapsedStates[this.port.productUid]
    } else if (this.associatedConnectionsCount > 10) {
      this.isCollapsed = true
    }

    this.$root.$on('collapseAll', () => {
      if (this.hasAssociatedConnections) {
        this.isCollapsed = true
        this.addCollapsedServiceUid(this.port.productUid)
      }
    })
    this.$root.$on('expandAll', () => {
      this.isCollapsed = false
      this.removeCollapsedServiceUid(this.port.productUid)
    })
  },

  methods: {
    ...mapActions('Services', ['addCollapsedServiceUid', 'removeCollapsedServiceUid']),
    ...mapMutations('Services', ['setLockedState']),

    // Helper methods
    copyToClipboard,
    snakeToCapitalizedWords,

    async downloadLoa(productUid) {
      this.isLoading = true
      const url = `${sdk.instance.baseurl}/v2/product/${productUid}/loa`

      try {
        await downloadFile(url, { openInNewTab: true })
      } finally {
        this.isLoading = false
      }
    },
    getProductNameFromPortUid(productUid) {
      const port = this.myPorts.find(p => p.productUid === productUid)
      if (!port) {
        // This could happen where you have something defined locally as being diverse from
        // an existing port, but someone else has deleted the port that it is supposed to be
        // diverse from. We should probably update the port to not refer to the non-existent one...but what
        // if it's still loading and appears later? Anyway, this is probably a rare enough case to not
        // need to deal with it. After all, the only "damage" is that potentially the port purchase
        // request goes through specifying that it's in a specific zone when perhaps it didn't need
        // to be specified.
        return this.$t('general.unknown')
      }
      return port.productName
    },
    toggleCancelService(service) {
      this.cancelUid = service.productUid
      this.showCancelPanel = true

      this.trackButtonClick(service.provisioningStatus === this.G_PROVISIONING_DESIGN ? 'delete-config' : 'delete')
    },
    showOnMap(service, trackClick = false) {
      this.$emit('showOnMap', service.productUid)
      if (trackClick) this.trackButtonClick('view-map')
    },
    calculatePortSpeedText(port) {
      if (port.lagPortCount) {
        return convertSpeed(port._aggSpeed)
      } else {
        return convertSpeed(port.speed || port.portSpeed)
      }
    },
    getFormattedLagLocation(lag) {
      // The LAG data record doesn't have the location data set, but the base service does, so look it up.
      const service = this.services.find(aService => aService.productUid === lag.productUid)

      return service?._location?.formatted ? service._location.formatted.long : this.$t('general.unknown-location')
    },
    getStatusInfo(port) {
      const statusInfo = this.statusInfo.statuses.find(portStatus => portStatus.productUid === port.productUid)

      return statusInfo || {}
    },
    getServiceIcon(service) {
      if (service.productType === this.G_PRODUCT_TYPE_MVE) {
        return this.G_PRODUCT_TYPE_MVE
      }
      if (service.productType === this.G_PRODUCT_TYPE_MCR2) {
        return 'MCR'
      }
      if (service.lagPrimary || service.lagPortCount) {
        return 'LAG'
      }
      return this.G_PRODUCT_TYPE_MEGAPORT
    },
    getWhiteLabelClass(service) {
      return service.productType === this.G_PRODUCT_TYPE_MEGAPORT
        ? `wl-port-color`
        : `wl-mcr-color`
    },
    checkIfLoading(port) {
      return this.loadingUid === port.productUid
    },
    showDetails(service) {
      if (service.productType === this.G_PRODUCT_TYPE_MVE) {
        this.$router.push(`/mve/${service.productUid}`, () => {
          // empty function is intentional
        })
      } else if (service.provisioningStatus === this.G_PROVISIONING_DESIGN) {
        const serviceTypeUrlParam = this.isPort ? 'port' : 'mcr'

        this.$router.push(`/create-megaport/${serviceTypeUrlParam}/${service.productUid}`, () => {
          // empty function is intentional
        })
      } else {
        this.$router.push(`/edit-megaport/${service.productUid}`, () => {
          // empty function is intentional
        })
      }
      this.trackButtonClick(service.provisioningStatus === this.G_PROVISIONING_DESIGN ? 'edit-config' : 'edit')
    },
    isLastLag(lag) {
      // If the port has no sub LAGs, return false.
      if (!this.port._subLags) return false

      // Find the index of the sub LAG
      const index = this.port._subLags.findIndex(currentLag => currentLag.productUid === lag.productUid)

      // If not found, return false.
      if (index === -1) return false

      // If found, determine if last.
      return index === this.subLagCount - 1
    },
    /**
     * A method that returns the necessary data
     * for the delete button's tooltip and its disabled status
     * @param {Object} service - The service to be potentially deleted
     * @returns {{canDelete: boolean, message: string}} - {canDelete: whether the service can be deleted, message: message to be displayed on the tooltip}
     */
    getDeleteButtonData(service) {
      let productTypeString

      switch (service.productType) {
        case this.G_PRODUCT_TYPE_MCR2:
          productTypeString = 'productNames.mcr'
          break
        case this.G_PRODUCT_TYPE_MVE:
          productTypeString = 'productNames.mve'
          break
        default:
          productTypeString = 'productNames.port'
      }

      if (service.locked || service.adminLocked) {
        return {
          canDelete: false,
          message: this.$t('services.type-locked', { type: this.$t(productTypeString) }),
        }
      }

      // This can happen if a service is decommissioned after the services have been loaded
      // and has not yet been cleaned up during periodic updates.
      if (service.provisioningStatus === this.G_PROVISIONING_DECOMMISSIONED) {
        return {
          canDelete: false,
          message: this.$t('services.decommissioned'),
        }
      }

      // LAG primary cannot be deleted if any of its subLag ports are locked
      if (service.lagPrimary && service._subLags && service.provisioningStatus !== this.G_PROVISIONING_DESIGN) {
        for (const lag of service._subLags) {
          if (lag.locked || lag.adminLocked) {
            return {
              canDelete: false,
              message: this.$t('ports.lag-primary-deletion-locked'),
            }
          }
        }
      }

      if (service.provisioningStatus === this.G_PROVISIONING_LOADING) {
        return {
          canDelete: false,
          message: this.$t('general.loading-data'),
        }
      }

      if ([this.G_PROVISIONING_CANCELLED, this.G_PROVISIONING_CANCELLED_PARENT].includes(service.provisioningStatus)) {
        return {
          canDelete: false,
          message: this.$t('services.type-cancelled', { type: this.$t(productTypeString) }),
        }
      }

      if (service.provisioningStatus === this.G_PROVISIONING_DESIGN_DEPLOY) {
        return {
          canDelete: false,
          message: this.$t('services.type-being-deployed', { type: this.$t(productTypeString) }),
        }
      }

      // It's OK to delete a service in design so long as it is not a port being added to a LAG,
      // and removal of the port leaves the LAG with sufficient capacity for the connections.

      // Either a port, MVE, MCR, or a brand new LAG being designed.
      if (service.provisioningStatus === this.G_PROVISIONING_DESIGN && !service.aggregationId) {
        // If the service has the lagPortCount property, it's an in-design port part of a LAG.
        // Otherwise, it is a standalone port, MVE, or MCR as defined above.
        if (service.lagPortCount) {
          productTypeString = 'productNames.lag'
        }
        return {
          canDelete: true,
          message: this.$t('general.delete-type', { type: this.$t(productTypeString) }),
        }
      }

      // Either a not-in-design port part of a not-in-design LAG,
      // or an in-design port linked to a not-in-design LAG.
      if (service.aggregationId && !service.lagPrimary) {
        const lagPrimary = this.myPorts.find(aPort => {
          return aPort.lagPrimary && aPort.aggregationId === parseInt(service.aggregationId)
        })

        // The total number of ports excludes cancelled ones and is as follows:
        // Total = primary LAG + not-in-design ports + lagPortCount of extra in-design ports being added
        let allPorts = 0
        // Count the primary LAG port if not cancelled
        if (lagPrimary.provisioningStatus !== this.G_PROVISIONING_CANCELLED) allPorts++

        for (const currentSubLag of lagPrimary._subLags) {
          const subLagIsNotCancelled = currentSubLag.provisioningStatus !== this.G_PROVISIONING_CANCELLED
          const subLagIsInDesign = currentSubLag.provisioningStatus === this.G_PROVISIONING_DESIGN
          // Only count ports that have not been cancelled
          if (subLagIsNotCancelled) {
            // If in design, count all extra LAG ports, and if not, just count the port.
            subLagIsInDesign ? allPorts += currentSubLag.lagPortCount : allPorts++
          }
        }

        // If the port to be removed is not in design, decrease the counter by one.
        // Otherwise, decrease the counter by the lagPortCount of the ports in design.
        let remainingPorts = allPorts
        service.provisioningStatus === this.G_PROVISIONING_DESIGN ? remainingPorts -= service.lagPortCount : remainingPorts--

        // Calculate the remaining speed knowing that all ports in a LAG share the same speed
        const remainingSpeed = remainingPorts * lagPrimary.speed
        // We need to be able to support the speed of the fastest connection
        const allSpeeds = [].concat(...lagPrimary.associatedIxs.map(ix => ix.rateLimit), ...lagPrimary.associatedVxcs.map(vxc => vxc.rateLimit))
        const requiredSpeed = Math.max(...allSpeeds)

        if (remainingSpeed < requiredSpeed) {
          return {
            canDelete: false,
            message: this.$t('ports.deletion-insufficient-capacity'),
          }
        }

        // If it's in design, we know it can be deleted.
        // There's no need to check permissions.
        if (service.provisioningStatus === this.G_PROVISIONING_DESIGN) {
          const type = this.$tc('ports.pluralize-ports', service.lagPortCount)
          return {
            canDelete: true,
            message: this.$t('general.delete-type', { type: type }),
          }
        }

      } else {
        // It's either not a LAG port, or it's the LAG primary port
        // so we need to check if any attached services are locked.
        for (const vxc of service.associatedVxcs) {
          if (vxc.locked || vxc.adminLocked) {
            return {
              canDelete: false,
              message: this.$t('ports.attached-vxc-locked'),
            }
          }
        }

        for (const ix of service.associatedIxs) {
          if (ix.locked || ix.adminLocked) {
            return {
              canDelete: false,
              message: this.$t('ports.attached-ix-locked'),
            }
          }
        }
      }

      // If we got up to here, we're deleting something that has been deployed,
      // so we need to check that we have permission to do so.
      if (this.canPlaceOrder) {
        if (service.lagPrimary) {
          productTypeString = 'productNames.lag'
        }

        return {
          canDelete: true,
          message: this.$t('general.delete-type', { type: this.$t(productTypeString) }),
        }
      } else {
        return {
          canDelete: false,
          message: this.$t('general.delete-permission-denied', { type: this.$t(productTypeString) }),
        }
      }
    },
    determineDetailsTooltipContent(service) {
      // Determine the product type sub-string for the translation string
      let productTypeSubstring

      switch (service.productType) {
        case this.G_PRODUCT_TYPE_MCR2:
          productTypeSubstring = 'mcr'
          break
        case this.G_PRODUCT_TYPE_MVE:
          productTypeSubstring = 'mve'
          break
        default:
          productTypeSubstring = service.lagPrimary || service.lagPortCount ? 'lag' : 'port'
      }

      // Determine the content based on product type
      return this.$t('general.type-details', { type: this.$t(`productNames.${productTypeSubstring}`) })
    },
    determineLockTooltipContent(service) {
      // Determine the translation sub-string based on product type
      let productTypeString

      switch (service.productType) {
        case this.G_PRODUCT_TYPE_MCR2:
          productTypeString = 'productNames.mcr'
          break
        case this.G_PRODUCT_TYPE_MVE:
          productTypeString = 'productNames.mve'
          break
        default:
          productTypeString = service.lagPrimary || service.lagPortCount ? 'productNames.lag' : 'productNames.port'
      }

      // If the service is adminLocked, no one can unlock it, not even admin users.
      // This means that Megaport itself has locked the service.
      if (service.adminLocked) {
        return this.$t('services.type-locked-megaport', { type: this.$t(productTypeString) })
      }

      // If the service is just 'locked', only admin users can unlock it.
      // This means that an admin user has locked the service.
      if (service.locked) {
        if (this.isAdmin) {
          // If an admin user, determine the content based on whether the service is live
          // since only services that are LIVE can be unlocked.
          if (service.provisioningStatus === this.G_PROVISIONING_LIVE) {
            return this.$t('services.type-unlock', { type: this.$t(productTypeString) })

          } else {
            return this.$t('services.type-unlock-notlive', { type: this.$t(productTypeString) })
          }

        } else {
          // If not an admin user, the service cannot be unlocked, so return the relevant content.
          return this.$t('services.type-locked-admin', { type: this.$t(productTypeString) })
        }

      } else if (this.isAdmin) {
        // If an admin user and the service is unlocked, determine the content based on whether the service is live
        // since only services that are LIVE can be locked.
        if (service.provisioningStatus === this.G_PROVISIONING_LIVE) {
          return this.$t('services.type-lock', { type: this.$t(productTypeString) })

        } else {
          return this.$t('services.type-lock-notlive', { type: this.$t(productTypeString) })
        }

      } else {
        // If not an admin user, the service cannot be locked, so return the relevant content.
        return this.$t('services.type-unlocked-admin', { type: this.$t(productTypeString) })
      }
    },
    determineAddConnectionTooltipContent(isLag = false) {
      // Determine the product type sub-string for the translation string
      let productTypeSubstring

      if (isLag) {
        productTypeSubstring = 'lag'
      } else {
        switch (this.port.productType) {
          case this.G_PRODUCT_TYPE_MCR2:
            productTypeSubstring = 'mcr'
            break
          case this.G_PRODUCT_TYPE_MVE:
            productTypeSubstring = 'mve'
            break
          default:
            productTypeSubstring = 'port'
        }
      }

      // Determine the content based on product type and whether connections can be added
      if (this.canConnect) {
        return this.$t('ports.add-connection-to', { type: this.$t(`productNames.${productTypeSubstring}`) })
      } else {
        return this.$t(`connections.${productTypeSubstring}-full`)
      }
    },
    lockPort(port) {
      this.loadingUid = port.productUid
      sdk.instance
        .product(port.productUid)
        .lock()
        .then(() => {
          this.loadingUid = null
          this.setLockedState({ obj: port, locked: true })
        })
        .catch(e => {
          this.loadingUid = null
          captureSentryError(e)
        })
      this.trackButtonClick('lock')
    },
    unlockPort(port) {
      this.loadingUid = port.productUid
      sdk.instance
        .product(port.productUid)
        .unlock()
        .then(() => {
          this.loadingUid = null
          this.setLockedState({ obj: port, locked: false })
        })
        .catch(e => {
          this.loadingUid = null
          captureSentryError(e)
        })
      this.trackButtonClick('unlock')
    },
    moveConnections() {
      this.$router.push(`/move-connections/${this.port.productUid}`)
      this.trackButtonClick('move-connections')
    },
    addConnection() {
      this.$router.push(`/create-connection/${this.port.productUid}`)
      this.trackButtonClick('add-connection')
    },
    trackButtonClick(buttonName) {
      captureEvent(`services.${productTypeToEvent(this.port.productType)}.${buttonName}.click`)
    },
    handleExpandCollapse() {
      this.isCollapsed ? this.removeCollapsedServiceUid(this.port.productUid) : this.addCollapsedServiceUid(this.port.productUid)
      this.isCollapsed = !this.isCollapsed
    },
  },
}
</script>

<style lang="scss" scoped>
.port-line {
  max-width: 900px;
  min-width: 400px;
  min-height: 90px;
  margin: 0 auto;
  padding: 5px 5px 5px 18px;
  background-color: var(--color-white);
  &.filterMatch {
    background-color: var(--color-primary-light-9);
  }
  border: 1px solid var(--border-color-base);
  border-radius: var(--border-radius-base);
  display: flex;
  position: relative;
  align-items: center;

  .service-info-text {
    margin-left: 5rem;
  }
}
.lag-group {
  display: block;
  padding-bottom: 0;
  .port-line {
    padding: 0;
    border: none;
    border-top: 1px solid var(--border-color-base);
    border-radius: 0;
  }
}
.port-sub-content {
  max-width: 900px;
  min-width: 400px;
  margin: 0 auto;
  padding: 0 9px 0 16px;
}
.configured-extras {
  min-height: 90px;
  padding: 5px;
  background-color: var(--color-white);
  border: 1px solid var(--border-color-base);
  border-top: none;
  &:last-child {
    border-bottom-left-radius: var(--border-radius-base);
    border-bottom-right-radius: var(--border-radius-base);
    margin-bottom: 1rem;
  }
  &.services-collapsed {
    min-height: unset;
  }
}
.disabled {
  color: var(--font-color-disabled-base);
}
.expand-arrow {
  transition: transform 0.3s;
  &.active {
    transform: rotate(180deg);
  }
  &:focus {
    outline: none;
  }
}
i.action-icon.adminLocked {
  color: var(--color-danger);
}

.min-width-300px {
  min-width: 300px;
}

.height-1rem {
  height: 1rem;
}

.action-button {
  margin-inline: 0 !important;
}
</style>

<style lang="scss">
button .el-loading-spinner {
  margin-top: -0.5em;
  .circular {
    height: 1em;
    width: 1em;
  }
  .path {
    stroke-width: 0.3em;
  }
}

.service-header,
.lag-header {
  font-size: 1.4rem;
  font-weight: 500;
  color: var(--color-text-primary);
  word-break: break-all;
}
.service-details {
  font-size: 1.2rem;
  color: var(--color-text-regular);
}
.lag-buttons {
  gap: 0.25rem;
  button.el-button {
    margin-bottom: 5px;
    padding: 0 1.5rem;
    height: 40px;
    i {
      font-size: 1.8rem;
    }
    &.collapse-button {
      width: 44px;
      padding: 0;
    }
  }
  .is-disabled i {
    color: var(--color-text-regular-disabled);
  }
}
.action-block {
  margin: 3px;
  margin-left: auto;
  align-items: flex-end;
  gap: 0.25rem;

  .add-connection-holder {
    grid-column-start: span 3;
  }

  button.el-button {
    padding: 0;
    width: 44px;
    height: 40px;
    &.add-connection {
      width: 138px;
      .connection-text {
        font-size: 1.4rem;
      }
    }
    i {
      font-size: 1.8rem;
    }
  }
  .is-disabled i {
    color: var(--color-text-regular-disabled);
  }
}
.action-icon,
a.action-icon {
  padding: 5px;
  cursor: pointer;
  text-decoration: none;
  color: var(--color-text-primary);
  &:hover {
    color: var(--color-primary);
  }
}
.is-disabled .action-icon,
.is-disabled a.action-icon {
  cursor: not-allowed;
}

$connection-arrow-color: #dcdfe6;
$connection-arrow-thickness: 2px;
$connection-half-arrow-thickness: 1.5px;

.connection-arrow--vertical {
  position: absolute;
  height: 100%;
  left: calc(2.4rem - 1px);
  border-right: $connection-arrow-thickness solid $connection-arrow-color;
}
.connection-arrow--port-bottom {
  position: absolute;
  height: calc(100% - 7rem + 1px);
  top: calc(7rem - 1px);
  left: calc(4rem - 1px);
  &.lag-arrow {
    left: calc(2.2rem - 3px);
    border-left: $connection-arrow-thickness solid $connection-arrow-color;
    width: 3px;
  }
  &.last-lag {
    left: calc(2.2rem - 1px);
  }
  border-right: $connection-arrow-thickness solid $connection-arrow-color;
}
.connection-arrow--lag-top {
  position: absolute;
  height: calc(50% - 2rem + 1px);
  left: calc(2.2rem - 3px);
  top: 0;
  border-left: $connection-arrow-thickness solid $connection-arrow-color;
  width: 3px;
  border-right: $connection-arrow-thickness solid $connection-arrow-color;
}

.configured-extras:last-child .connection-arrow--vertical {
  height: 50%;
  top: 0;
}

.connection-arrow--horizontal {
  position: absolute;
  left: calc(2.4rem - 1px);
  top: 50%;
  width: 1.3rem;
  border-bottom: $connection-arrow-thickness solid $connection-arrow-color;
}

.move-connection-tooltip {
  // Will avoid using a specific value so it doesn't screw up translations
  // Already put in the grunt work to ensure all the tooltip text isn't too long in the first place
  max-width: unset;
}

.configured-extras.filterMatch {
  background-color: var(--color-primary-light-9);
}

i.action-icon.adminLocked {
  color: var(--color-danger);
}

.service-info-text {
  min-width: 300px;
  margin: 0.5rem 0.5rem 0.5rem 7.8rem;
}
</style>
