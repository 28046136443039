<template>
  <div class="configured-extras flex-row-centered service-box position-relative"
    :class="{filterMatch: matchingServices.includes(vxc.productUid)}"
    data-testid="vxc"
    :data-service-name="calculatedServiceName"
    :data-service-type="calculatedServiceType"
    :data-provisioning-status="vxc.provisioningStatus">
    <cancel-service v-if="showCancelPanel"
      :visible.sync="showCancelPanel"
      :service-uid="vxc.productUid" />

    <div class="connection-arrow--vertical" />
    <div class="connection-arrow--horizontal" />

    <lazy-popover width="fit-content"
      placement="top"
      :open-delay="500"
      trigger="hover">
      <div class="text-align-left fs-1-4rem p-1">
        <p class="text-align-center my-0">
          <strong>{{ statusInfo.message }}</strong>
        </p>
        <div v-if="statusInfo.descriptor"
          class="flex-row-centered position-relative mt-2 mb-1">
          <div class="service-status-icon"
            :class="statusInfo.status" />
          <p class="ml-1 my-0-5">
            {{ statusInfo.descriptor }}
          </p>
        </div>
        <template v-if="statusInfo.bgpStatuses.length">
          <p class="mt-2 mb-1">
            <strong>{{ $t('connections.bgp-statuses') }}</strong>
          </p>
          <div v-for="(status, index) in statusInfo.bgpStatuses"
            :key="index"
            class="flex-row-centered position-relative">
            <div class="service-status-icon"
              :class="status.status" />
            <p class="ml-1 my-0-5">
              {{ status.message }}
            </p>
          </div>
        </template>
      </div>

      <template #reference>
        <mu-mega-icon :icon="vxcIcon"
          class="service-icon service-status-indicator service-page-service-icon"
          :class="[whiteLabelClass, statusInfo.status]"
          :data-service-name="calculatedServiceName"
          :data-status="vxc.provisioningStatus"
          data-testid="service-icon"
          @click="showDetails()" />
      </template>
    </lazy-popover>

    <div class="cursor-pointer service-info-text"
      @click="showDetails()">
      <div class="service-header">
        <span :data-service-name="calculatedServiceName">
          {{ calculatedServiceName }}
        </span>
        <el-tooltip v-if="!serviceIsInDesign"
          placement="right"
          :content="$t('services.identifier-copy')"
          :open-delay="500">
          <span class="service-identifier"
            @click.stop="copyToClipboard(serviceUid)">#{{ serviceUid }}</span>
        </el-tooltip>
        <span v-if="!serviceIsLive && serviceIsShutdown"
          class="ml-0-5">({{ snakeToCapitalizedWords(vxc.provisioningStatus) }}, {{ $t('general.shut-down') }})</span>
        <span v-else-if="!serviceIsLive || serviceIsShutdown"
          class="ml-0-5">({{ !serviceIsLive ? snakeToCapitalizedWords(vxc.provisioningStatus) : $t('general.shut-down') }})</span>
      </div>
      <div class="service-details"
        :data-speed="displaySpeed">
        <strong>{{ endOfThisVxc }} {{ $t('general.end') }}{{ aEndOwned ? '' : ` (${$t('connections.third-party-owner')})` }}</strong>
        {{ $t('productNames.vxc') }} ({{ displaySpeed }})
        <span v-if="otherEndName"> - {{ otherEndName }}</span>
      </div>
      <div v-if="otherEndDiversityZone"
        class="service-details"
        :title="$t(`services.${otherEndDiversityZone}-zone`)">
        {{ $t('services.diversity-zone') }}
        <i class="far fa-dot-circle"
          :class="`diversity-color-${otherEndDiversityZone}`"
          aria-hidden="true" />
      </div>
      <conflict :service="vxc" />
    </div>
    <div class="action-block tool-buttons vxc-block"
      role="menubar">
      <!-- Only marketplace icons that are CSPs are showed here -->
      <el-tooltip v-if="marketplaceProfile"
        placement="top"
        :content="marketplaceProfile.companyName"
        :open-delay="500">
        <template v-if="disabledFeatures.marketplace">
          <img :src="marketplaceProfile._logo"
            :alt="$t('images.profile-logo')"
            class="marketplaceLogo mx-1"
            @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
        </template>
        <template v-else>
          <router-link :to="`/marketplace/${marketplaceProfile.slug}`">
            <el-button class="mp-profile">
              <img :src="marketplaceProfile._logo"
                :alt="$t('images.profile-logo')"
                class="marketplaceLogo"
                @error="setFallbackImage($event, '/fallback-images/mp-placeholder.png')">
            </el-button>
          </router-link>
        </template>
      </el-tooltip>

      <el-tooltip placement="top"
        :content="moveTooltip"
        :open-delay="500"
        popper-class="move-connection-tooltip">
        <span>
          <!-- Don't use router-link here otherwise we can't properly disable navigation -->
          <el-button :disabled="noMoveReason != null"
            name="moveConnection"
            data-testid="move-connection"
            @click="moveVxc()">
            <i class="fal fa-exchange action"
              aria-hidden="true" />
          </el-button>
        </span>
      </el-tooltip>

      <el-tooltip placement="top"
        :open-delay="500">
        <template #content>
          <div class="text-align-center">
            <div>{{ $t('general.type-details', { type: $t('productNames.vxc') }) }}</div>
            <div v-if="serviceIsShutdown">
              {{ $t('general.type-shutdown-state', { type: $t('productNames.vxc') }) }}
            </div>
          </div>
        </template>
        <span>
          <el-button name="showDetails"
            :class="{ 'is-shutdown': serviceIsShutdown }"
            data-testid="edit-vxc"
            @click="showDetails()">
            <i :class="serviceIsShutdown ? 'fa' : 'fal'"
              class="fa-cog action"
              aria-hidden="true" />
          </el-button>
        </span>
      </el-tooltip>

      <el-tooltip v-if="!disabledFeatures.dashboard"
        placement="top"
        :content="$t('map.view-in-map')"
        :open-delay="500">
        <span>
          <el-button name="show-on-map"
            label="map"
            @click="showOnMap">
            <i class="fal fa-location-dot action" />
          </el-button>
        </span>
      </el-tooltip>

      <el-tooltip placement="top"
        :content="deleteTooltip"
        :open-delay="500">
        <span>
          <el-button data-name="deleteService"
            data-testid="delete-vxc"
            :disabled="!canDeleteVXC"
            @click="toggleCancelService()">
            <i class="fal fa-trash-alt action"
              aria-hidden="true" />
          </el-button>
        </span>
      </el-tooltip>

      <el-tooltip v-show="!vxc.locked && !vxc.adminLocked && showLock"
        placement="top"
        :content="lockTooltip"
        :open-delay="500">
        <span>
          <el-button v-loading="loading"
            :disabled="!isAdmin || !serviceIsLive"
            name="lock"
            data-testid="lock-vxc"
            @click="changeVxcLockStatus(true)">
            <i class="fal fa-unlock-alt action"
              aria-hidden="true" />
          </el-button>
        </span>
      </el-tooltip>
      <el-tooltip v-show="vxc.locked || vxc.adminLocked"
        placement="top"
        :content="lockTooltip"
        :open-delay="500">
        <span>
          <el-button v-loading="loading"
            :disabled="!isAdmin || !serviceIsLive || vxc.adminLocked"
            name="unlock"
            data-testid="unlock-vxc"
            @click="changeVxcLockStatus(false)">
            <i class="fal fa-lock action"
              :class="{adminLocked: vxc.adminLocked}"
              aria-hidden="true" />
          </el-button>
        </span>
      </el-tooltip>
    </div>
  </div>
</template>

<script>
// External tools
import { mapState, mapGetters } from 'vuex'
import sdk from '@megaport/api-sdk'
// Internal tools
import captureSentryError from '@/utils/CaptureSentryError.js'
import { copyToClipboard, convertSpeed, snakeToCapitalizedWords, slug } from '@/helpers.js'
import { connectionNoMoveReason, portlikeNoMoveReason } from '@/utils/moveConnections'
import { setFallbackImage } from '@/utils/fallbackImage'
import { captureEvent, productTypeToEvent } from '@/utils/analyticUtils'
import { CLOUD_ITEMS } from '@/Globals.js'
// Components
import ConflictComponent from '@/components/services/Conflict.vue'
import CancelServiceComponent from '@/components/cancel/Cancel.vue'
import LazyPopoverComponent from '@/components/ui-components/LazyPopover.vue'

export default {
  name: 'VXC',

  components: {
    conflict: ConflictComponent,
    'cancel-service': CancelServiceComponent,
    'lazy-popover': LazyPopoverComponent,
  },

  inject: ['disabledFeatures'],

  props: {
    vxc: {
      type: Object,
      required: true,
    },
    port: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      statsOpen: false,
      loading: false,
      showCancelPanel: false,
    }
  },

  computed: {
    ...mapState('Marketplace', ['marketplaceData']),
    ...mapGetters('Auth', ['hasAuth']),
    ...mapGetters('Company', ['companyUid']),
    ...mapGetters('Services', ['findPort', 'showLock']),
    ...mapGetters('ServiceFilters', ['matchingServices']),

    whiteLabelClass() {
      return this.serviceIsInDesign ? 'wl-vxc-configured-color' : 'wl-vxc-color'
    },
    vxcIcon() {
      if (this.isTransitVXC) {
        return 'MegaportInternet'
      } else {
        return 'VXC'
      }
    },
    isTransitVXC() {
      return this.otherEnd?.connectType === 'TRANSIT'
    },
    statusInfo() {
      const statusInfo = {
        status: '',
        message: '',
        descriptor: '',
        bgpStatuses: [],
      }

      if (!this.serviceIsLive) {
        statusInfo.message = `${this.$t('services.provisioning-status')}: ${this.vxc.provisioningStatus}`
      } else if (typeof this.vxc.up !== 'boolean') {
        statusInfo.status = 'partial-success'
        statusInfo.message = this.$t('connections.vxc-status-unknown')
      } else {
        statusInfo.descriptor = this.vxc.resources?.vll?.description

        statusInfo.status = this.vxc.up ? 'all-go' : 'all-fail'
        statusInfo.message = this.vxc.up ? this.$t('connections.vxc-is-up') : this.$t('connections.vxc-is-down')

        // There is only BGP information for MCRs, and it is part of the csp_connection object.
        // Add the BGP statuses as informational content.
        let csps = []

        // The API is currently returning csp_connection sometimes as an array and sometimes as an object,
        // which seems to depend on whether a single connection exists (object) or multiple (array).
        // Because of this, we need to check what we get and if not an array,
        // push the object into an empty array so that we can handle both cases similarly.
        if (this.vxc.resources?.csp_connection) {
          if (Array.isArray(this.vxc.resources.csp_connection)) {
            csps = this.vxc.resources.csp_connection
          } else {
            csps.push(this.vxc.resources.csp_connection)
          }
        }

        for (const cspInfo of csps) {
          if (cspInfo.connectType && cspInfo.connectType === 'VROUTER' && cspInfo.bgp_status) {
            let connections = []

            if (cspInfo.interfaces && cspInfo.interfaces[0].bgpConnections) {
              connections = cspInfo.interfaces[0].bgpConnections
            }

            const keys = Object.keys(cspInfo.bgp_status)

            for (const key of keys) {
              const bgpInfo = connections.find(connection => connection.peerIpAddress === key) || {}
              const thisStatus = {}

              thisStatus.message = `${this.$t('services.ip-address')}: ${key} ${(bgpInfo.description || '')}`

              if (bgpInfo.shutdown) {
                thisStatus.message += ` (${this.$t('connections.shut-down-by-user')})`
              }

              switch (cspInfo.bgp_status[key]) {
                case 0:
                  thisStatus.status = 'all-fail'
                  break
                case 1:
                  thisStatus.status = 'all-go'
                  break
                default:
                  thisStatus.status = 'partial-success'
                  break
              }

              statusInfo.bgpStatuses.push(thisStatus)
            }
          }
        }
      }

      return statusInfo
    },
    isAdmin() {
      return this.hasAuth('company_admin')
    },
    aEndOwned() {
      return (this.endOfThisVxc === 'B' && this.vxc.aEnd.ownerUid !== this.companyUid) ? false : true
    },
    canDeleteVXC() {
      if (this.vxc.adminLocked || this.vxc.locked) return false
      if ([this.G_PROVISIONING_DESIGN_DEPLOY, this.G_PROVISIONING_FAILED, this.G_PROVISIONING_DECOMMISSIONED].includes(this.vxc.provisioningStatus)) return false
      if (this.pendingInternalApproval) return false
      return this.serviceIsInDesign || this.canPlaceOrder
    },
    canPlaceOrder() {
      return this.hasAuth('place_order')
    },
    noMoveReason() {
      return portlikeNoMoveReason(this.port) || connectionNoMoveReason(this.vxc)
    },
    moveTooltip() {
      if (this.noMoveReason) {
        return this.$t(this.noMoveReason, { type: this.$t('productNames.vxc') })
      }
      return this.$t('connections.move-connection')
    },
    deleteTooltip() {
      if (this.vxc.locked || this.vxc.adminLocked) {
        return this.$t('services.type-locked', { type: this.$t('productNames.vxc') })
      } else if (this.vxc.provisioningStatus === this.G_PROVISIONING_DESIGN_DEPLOY) {
        return this.$t('services.type-being-deployed', { type: this.$t('productNames.vxc') })
      } else if (this.pendingInternalApproval) {
        return this.$t('connections.awaiting-approval-or-denial')
      } else if (this.vxc.provisioningStatus === this.G_PROVISIONING_DECOMMISSIONED) {
        // This can happen if a service is decommissioned after the services have been loaded
        // and has not yet been cleaned up during periodic updates.
        return this.$t('services.decommissioned')
      } else if (this.canDeleteVXC) {
        return this.$t('general.delete-type', { type: this.$t('productNames.vxc') })
      } else {
        return this.$t('general.delete-permission-denied', { type: this.$t('productNames.vxc') })
      }
    },
    lockTooltip() {
      if (this.vxc.adminLocked) {
        return this.$t('services.type-locked-megaport', { type: this.$t('productNames.vxc') })
      } else if (this.vxc.locked) {
        if (this.isAdmin) {
          if (this.serviceIsLive) {
            return this.$t('services.type-unlock', { type: this.$t('productNames.vxc') })
          } else {
            return this.$t('services.type-unlock-notlive', { type: this.$t('productNames.vxc') })
          }
        } else {
          return this.$t('services.type-locked-admin', { type: this.$t('productNames.vxc') })
        }
      } else if (this.isAdmin) {
        if (this.serviceIsLive) {
          return this.$t('services.type-lock', { type: this.$t('productNames.vxc') })
        } else {
          return this.$t('services.type-lock-notlive', { type: this.$t('productNames.vxc') })
        }
      } else {
        return this.$t('services.type-unlocked-admin', { type: this.$t('productNames.vxc') })
      }
    },
    showOtherEndDetails() {
      // Hide b-end details of in-design megaport internet for MVE as the real b-end is not chosen until after ordering
      return !(this.serviceIsInDesign && (this.isTransitVXC && this.port.productType === this.G_PRODUCT_TYPE_MVE))
    },
    /**
     * @returns the port at the other end or undefined if it could not be found
     */
    otherEnd() {
      return this.endOfThisVxc === 'A'
        ? this.findPort(this.vxc.bEnd.productUid)
        : this.findPort(this.vxc.aEnd.productUid)
    },
    otherEndName() {
      // Get the location info directly from the API data
      // Used for third party connections where other end can't be found
      if (!this.otherEnd) {
        const otherEnd = this.endOfThisVxc === 'A' ? 'bEnd' : 'aEnd'
        const locationString = this.showOtherEndDetails && this.vxc[otherEnd]?.locationDetail
          ? `${this.vxc[otherEnd]?.location}, ${this.vxc[otherEnd]?.locationDetail?.city}, ${this.vxc[otherEnd]?.locationDetail?.country}`
          : ''
        return locationString
      }

      const locationString = this.showOtherEndDetails && this.otherEnd._location?.formatted ? ` - ${this.otherEnd._location.formatted.long}` : ''
      return `${this.otherEnd.title}${locationString}`
    },
    otherEndDiversityZone() {
      // If the other end is undefined, then it's a third party connection,
      // so we can directly reference the saved diversity zone
      if (!this.otherEnd) {
        const oppositeEnd = this.endOfThisVxc === 'A' ? 'bEnd' : 'aEnd'
        return this.vxc[oppositeEnd].diversityZone
      }

      return this.otherEnd && this.showOtherEndDetails
        ? this.otherEnd.config?.diversityZone || this.otherEnd.diversityZone
        : null
    },
    displaySpeed() {
      return convertSpeed(this.vxc.rateLimit)
    },
    serviceUid() {
      return this.vxc.productUid.split('-')[0]
    },
    endOfThisVxc() {
      return this.port.productUid === this.vxc.bEnd.productUid ? 'B' : 'A'
    },
    marketplaceProfile() {
      // It was recently decided to only show marketplace profiles for CSPs
      // A silly decision but it is what it is 🥲
      const cloudCompanyUids = CLOUD_ITEMS.map(item => item?.companyUids).flat()

      if (!this.otherEnd) return false
      if (this.otherEnd.companyUid === this.companyUid) return false

      const mpp = this.marketplaceData.find(mp => this.otherEnd.companyUid === mp.companyUid)

      if (!mpp || !cloudCompanyUids.includes(mpp.companyUid)) return false

      return {
        ...mpp,
        slug: slug(mpp.companyName),
      }
    },
    calculatedServiceName() {
      // If the VXC is not owned in the A end, attempt to display its Secondary Name.
      // If that fails, or if the VXC is owned in the A end, display the Product Name.
      return !this.aEndOwned ? (this.vxc.secondaryName || this.vxc.productName) : this.vxc.productName
    },
    calculatedServiceType() {
      return this.isTransitVXC ? 'TransitVXC' : this.vxc.productType
    },
    serviceIsInDesign() {
      return this.vxc.provisioningStatus === this.G_PROVISIONING_DESIGN
    },
    serviceIsLive() {
      return this.vxc.provisioningStatus === this.G_PROVISIONING_LIVE
    },
    serviceIsShutdown() {
      return this.vxc.shutdown ?? this.vxc.resources?.vll?.shutdown ?? false
    },
    pendingInternalApproval() {
      return this.vxc.vxcApproval?.type === this.G_PROVISIONING_NEW && this.vxc.vxcApproval.status === this.G_PROVISIONING_PENDING_INTERNAL
    },
  },

  methods: {
    setFallbackImage,
    copyToClipboard,
    snakeToCapitalizedWords,

    toggleCancelService() {
      this.showCancelPanel = true
      this.trackButtonClick(this.serviceIsInDesign ? 'delete-config' : 'delete')
    },
    showDetails() {
      if (this.serviceIsInDesign) {
        this.$router.push(`/create-connection/${this.vxc.productUid}`, () => {})
        this.trackButtonClick('edit-config')
      } else {
        this.$router.push(`/edit-connection/${this.vxc.productUid}`, () => {})
        this.trackButtonClick('edit')
      }
    },
    showOnMap() {
      this.$emit('showOnMap', this.vxc)
      this.trackButtonClick('view-map')
    },
    changeVxcLockStatus(lock) {
      this.loading = true

      const method = lock ? 'lock' : 'unlock'
      this.trackButtonClick(method)

      sdk.instance
        .product(this.vxc.productUid)[method]()
        .then(() => {
          this.loading = false
          this.$store.commit('Services/setLockedState', { obj: this.vxc, locked: lock })
        })
        .catch(e => {
          this.loading = false
          captureSentryError(e)
        })
    },
    moveVxc() {
      this.$router.push(`/move-connections/${this.port.productUid}?connection=${this.vxc.productUid}`)
      this.trackButtonClick('move')
    },
    trackButtonClick(buttonName) {
      captureEvent(`services.${productTypeToEvent(this.port.productType)}.vxc.${buttonName}.click`)
    },
  },
}
</script>

<style lang="scss" scoped>
.grid-88-three-44 {
  -ms-grid-columns: 88px 44px 44px 44px;
  grid-template-columns: 88px repeat(3, 44px);
}
.grid-88-two-44 {
  -ms-grid-columns: 88px 44px 44px;
  grid-template-columns: 88px repeat(2, 44px);
}

.marketplaceLogo {
  height: 84px;
}
.action-block.vxc-block {
  margin-top: 0;
  margin-bottom: 0;
  span {
    align-self: center;
  }
  button.el-button.mp-profile {
    width: 88px;
    height: 88px;
  }
}
.not-owner-indicator {
  width: 88px;
  height: 88px;
  border: var(--border-base);
  border-radius: var(--button-border-radius);
  border-color: var(--button-default-border-color);

  svg {
    color: #ff0000;
    fill: currentColor;

    width: 68px;
    height: 68px;
    margin: 10px;
  }
}
</style>
